'use client'

import {useEffect} from 'react'

const ScrollToAnchor: React.FC<{offsetY?: number}> = ({offsetY = -25}) => {
	useEffect(() => {
		if (typeof window === 'undefined') return

		const hash = (window?.location?.hash || '#').substring(1)
		if (hash) {
			const el = document.getElementById(hash)

			if (!el) return
			const y = el.getBoundingClientRect().top + window.pageYOffset + offsetY

			const hashTimer = setTimeout(
				() => window.scrollTo({top: y, behavior: 'smooth'}),
				0
			)

			return () => clearTimeout(hashTimer)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return null
}

export default ScrollToAnchor
