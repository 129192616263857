import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/public/logo-allianz.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/public/logo-val-white.svg");
;
import(/* webpackMode: "eager", webpackExports: ["GallerySlides"] */ "/home/runner/work/atletiek.be/atletiek.be/src/blocks/gallery/GallerySlides.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CalendarGroups"] */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/calendar/CalendarGroups.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/calendar/CalendarWithFilters.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DocumentResults"] */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/documents/DocumentResults.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/rankings/RankingsClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchResults"] */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/search/SearchResults.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/components/ads/Ads.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/components/breadcrumbs/Breadcrumbs.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/components/cards/PersonCard.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/components/events/Events.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FindClubForm"] */ "/home/runner/work/atletiek.be/atletiek.be/src/components/findclub/FindClubForm.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/components/introcontent/IntroContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IntroImage"] */ "/home/runner/work/atletiek.be/atletiek.be/src/components/introimage/IntroImage.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/components/news/News.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsContent"] */ "/home/runner/work/atletiek.be/atletiek.be/src/components/news/NewsContent.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/components/news/NewsRelated.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsTags"] */ "/home/runner/work/atletiek.be/atletiek.be/src/components/news/NewsTags.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/components/partners/Partners.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/components/cards/TopbarCard.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/atletiek.be/atletiek.be/src/components/scrolltoanchor/ScrollToAnchor.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/components/topbar/Topbar.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/athlete/Athlete.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/admin/AdminTools.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/icons/arrow-left-solid.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/icons/arrow-left.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/icons/arrow-right-solid.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/icons/arrow-right.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/icons/chevron-down-solid.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/icons/close.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/icons/facebook.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/icons/instagram.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/icons/linkedin.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/icons/magnifying-glass-solid.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/icons/twitter.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/icons/youtube.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/Image.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/LabelValue.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/athletes/Athletes.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/Link.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/Title.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/LinkStyled.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/az_list/Azlist.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/calendar/Calendar.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/blocks/cards/CardsBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/blocks/gallery/GalleryBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/blocks/iframe/IframeBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/blocks/image/ImageBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/blocks/list/ListBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/blocks/text/TextBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/blocks/videotext/VideotextBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/Button.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/calendar_item/CalendarDetail.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/club/ClubEvents.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/club/ClubFacilities.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/club/Club.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/cookie_policy/CookiePolicy.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/documents/Documents.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/components/contentside/Contentside.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/education_detail/EducationDetail.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/educations/Educations.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/faq/FaqOverview.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/faq/Faq.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/components/cards/NewsCard.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/components/hero/Hero.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/components/hero/HeroCategory.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/components/embedsocial/EmbedSocial.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/components/mixedsection/MixedSection.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/homepage/Home.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/list/List.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/main_category/MainCategory.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/news_overview/News.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/components/moreinfo/Moreinfo.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/news_item/NewsDetail.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/overview/Overview.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/components/findclub/FindClub.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/page/Page.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/person/Person.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/persons/Persons.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/rankings/Rankings.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/refresher_course_detail/RefreshercourseDetail.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/refresher_courses/Refreshercourses.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/channels/search/Search.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Video"] */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/Video.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WysiwygClientWrapper"] */ "/home/runner/work/atletiek.be/atletiek.be/src/layout/wysiwyg/WysiwygClientWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/atletiek.be/atletiek.be/src/util/QueryClientProvider.tsx");
